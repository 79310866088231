import Button from 'react-bootstrap/Button';

import PngIcons from './icons/Pngincons';
import Slider from './Slider';
import NavbarComp from './Navbar';
import { useNavigate } from 'react-router-dom';

const About = () => {


  const navigate=useNavigate()


  return (
    <div className='w-100' id='aboutcontainer' >

<NavbarComp/>


      <div className='aboutus-firstbox'>
        <p className='aboutus-firstabout'>
          About us
        </p>
        <h1 className='aboutus-firsthead'>
          Smish Trap is Helping to Build The Human Firewall Worldwide.
          <p className='aboutus-firstpara'>
            Accelerate your wireframing workflow using pre-build components or combine your own.
          </p>
        </h1>

      </div>


      <div className='Security-section-secondbox'>
        <div className='Security-section-compflex'>
          <img alt='' className='Security-section-compimg' src={PngIcons.component}></img>


          <div className='Security-section-comptext'>
            <h1>Let's Improve Security <br></br> Together
              <p>
                Red Team Analysts are frequently tasked with devising an exhaustive list of potential attack scenarios, often an impossible feat given the ever-evolving landscape of cyber threats. We at PhishTrap were recently challenged with having to create and deploy a smishing campaign.  We immediately turned to Google and were left with a pile of legal headaches, paperwork and configuration to use a technology create in 1992.
              </p>
              <h3>
                In response, we’ve developed a self-service, fully automated solution that requires no human intervention—empowering organizations to stay ahead of threats independently.
              </h3>

              <h3>
              Our mission is to educate employees on recognizing and avoiding phishing scams, comprehending various cyber risks, and implementing cybersecurity best practices. Through our product, users can build their knowledge and resilience against potential threats, helping to safeguard their organizations against malicious activities.
              </h3>
              <p className='Security-section-newsecondpara'>
          By integrating our solution, you’ll be taking a proactive step towards a more secure future. Try us today, and together, we can make the world a safer place for everyone.
        </p>

        <div className='Security-section-signsignnewsecondpara'>
          Greg Bammel
        </div>
            </h1>
          </div>
        </div>
        {/* <p style={{ marginTop: '60px' }} className='newsecondpara'>

          Our mission is to educate employees on recognizing and avoiding phishing scams, comprehending various cyber risks, and implementing cybersecurity best practices. Through our product, users can build their knowledge and resilience against potential threats, helping to safeguard their organizations against malicious activities.





        </p> */}


      

      </div>

      {/* fifth box */}
      <div className='opportunity-section-fifthbox'>
        <div className='opportunity-section-mainbox'>
          <div className='opportunity-section-mainboxheading'>
            Don't miss this opportunity to try Smish Trap
            <p className='opportunity-section-mainboxpara'>
              Accelerate your wireframing workflow using pre-build components or combine your own.
            </p>
            <div className='opportunity-section-fiftbtn'>
              <Button   onClick={()=>{navigate('/comingsoon')}} className='opportunity-section-fifthfirstbtn'>Get Started</Button>
              <Button onClick={()=>{navigate('/contact')}} className='opportunity-section-fifthsecondbtn'>
                Contact Us</Button>
            </div>
          </div>
          {/* <img className='fifthimage' src={PngIcons.phone}></img> */}
          <img alt='' className='opportunity-section-fifthimage' src={PngIcons.phone}></img>
          <img alt='' className='frameimg' src={PngIcons.newframe}></img>
         
        </div>
      </div>

      {/* six box */}

      <div className='review-section-sixbox'>
        <div >
          <h2 className='review-section-sixboxhead'>
            Why people like us?
          </h2>
          <p className='review-section-sixboxpara'>
            Let’s see what our customers are saying
          </p>
        </div>

        <div className='carousel'>
        <Slider/>
        </div>



      </div>




      {/* footer */}
      <div className='footer'>

        <div className='foootermain'>
          <img alt='' className='footerlogo' src={PngIcons.footerlogo}>
          </img>
          <p className='footerpara'>
            Our mission is to educate employees on recognizing and avoiding phishing scams, comprehending various cyber risks, and implementing cybersecurity best practices.
          </p>

          <img alt='' className='social' src={PngIcons.social}></img>

        </div>

        <div className='links'>
          <div className='linksname'>
            Home
          </div>
          <div style={{ color: '#EF4444' }} className='linksname'>
            About Us
          </div>
          <div className='linksname'>
            Smishing 101
          </div>

          <div className='linksname'>
            Why Smish
          </div>

          <div className='linksname'>
            Contact Us
          </div>

        </div>

        <p className='linksname'>
          © Copyright 2024 Smish Trap.
        </p>

      </div>



    </div>
  )
}


export default About;