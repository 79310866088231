import Button from 'react-bootstrap/Button';

import PngIcons from './icons/Pngincons';
import NavbarComp from './Navbar';
import { useNavigate } from 'react-router-dom';



const JoinSmish = () => {

    const navigate=useNavigate()


    return (
        <div className='w-100' id='joinsmishingcontainer' >

            <NavbarComp />


            <div className='join-smish-firstbox'>

                <p className='join-smish-firstabout'>
                    Why Smish
                </p>

                <h1 className='join-smish-firsthead'>
                    Automated Smishing Simulations with Realistic, Native Content
                    <p className='join-smish-firstpara'>
                        Smish Trap simulations train your employees to immediately identify and report smishing attacks, all without burdening your IT team.
                    </p>
                </h1>

            </div>


            <div className='Improve-Security-secondbox'>
                <div className='Improve-Security-compflex'>
                    <img alt='' className='Improve-Security-compimg' src={PngIcons.component}></img>


                    <div className='Improve-Security-comptext'>

                        <h1>
                            Let's Improve Security <br></br> Together

                            <p>
                                Red Team Analysts are frequently tasked with devising an exhaustive list of potential attack scenarios, often an impossible feat given the ever-evolving landscape of cyber threats. We at PhishTrap were recently challenged with having to create and deploy a smishing campaign.  We immediately turned to Google and were left with a pile of legal headaches, paperwork and configuration to use a technology create in 1992.

                            </p>
                            <h3>


                                In response, we’ve developed a self-service, fully automated solution that requires no human intervention—empowering organizations to stay ahead of threats independently.
                            </h3>
                        </h1>

                        <Button onClick={()=>{navigate('/comingsoon')}} className='smashingsigninwork'>Get Started</Button>
                    </div>
                </div>


            </div>




            {/* thirdbox */}
            <div className='Mechanics-Smishing-thirdbox'>
                <h1 className='Mechanics-Smishing-thirdjoinhead'>
                    The Mechanics of a Smishing Attack
                    <p className='Mechanics-Smishing-thirdjoinpara'>
                        Smishing attacks can be broken down into a few key steps:
                    </p>
                </h1>

                <div className='Mechanics-Smishing-thirdmainnumbox'>
                    <div className='thirdnum'>
                        <div className='joinnumber'>
                            01
                            <h1 className='joinnumberhead'>
                                Fake Message Creation
                                <p className='joinnumberpara'>
                                    Attackers craft messages that mimic trusted sources, like banks or delivery services, using fake names or phone numbers.
                                </p>
                            </h1>

                        </div>
                        <div className='joinnumber'>
                            02
                            <h1 className='joinnumberhead'>
                                Urgent Call to Action
                                <p className='joinnumberpara'>
                                    The message urges quick action, like clicking a link, sharing personal info, or calling a number, creating a sense of urgency.
                                </p>
                            </h1>

                        </div>

                    </div>

                    <div className='thirdnum'>
                        <div className='joinnumber'>
                            03
                            <h1 className='joinnumberhead'>
                                Redirect to Malicious Sites or Numbers
                                <p className='joinnumberpara'>
                                    Clicking the link leads to fake websites or malware downloads, or calling a number connects to fake customer service lines for data theft.
                                </p>
                            </h1>

                        </div>
                        <div className='joinnumber'>
                            04
                            <h1 className='joinnumberhead'>
                                Information Theft
                                <p className='joinnumberpara'>
                                    Attackers use the stolen data to access bank accounts, commit identity theft, or sell the information on the dark web.
                                </p>
                            </h1>

                        </div>

                    </div>
                </div>
            </div>



            {/* fourth box */}

            <div className='improve-security-fourthbox'>
                <div className='improve-security-fourthboxmain'>




                    <div className='improve-security-fourthsimphead'>
                        Let's Improve Security <br></br> Together

                        <p>
                            Red Team Analysts are frequently tasked with devising an exhaustive list of potential attack scenarios, often an impossible feat given the ever-evolving landscape of cyber threats. We at PhishTrap were recently challenged with having to create and deploy a smishing campaign.  We immediately turned to Google and were left with a pile of legal headaches, paperwork and configuration to use a technology create in 1992.

                        </p>
                        <p >


                            In response, we’ve developed a self-service, fully automated solution that requires no human intervention—empowering organizations to stay ahead of threats independently.
                        </p>
                        <Button onClick={()=>{navigate('/comingsoon')}} className='smashingsigninwork'>Get Started</Button>
                    </div>

                    <img alt='' className='simishingphone' src={PngIcons.smsihingspam}></img>

                </div>
            </div>


            {/* fifth box */}

            <div className='opportunity-fifthbox'>
                <div className='opportunity-mainbox'>
                    <div className='opportunity-mainboxheading'>
                        Don't miss this opportunity to try Smish Trap
                        <p className='opportunity-mainboxpara'>
                            Accelerate your wireframing workflow using pre-build components or combine your own.
                        </p>
                        <div className='opportunity-fiftbtn'>
                            <Button onClick={()=>{navigate('/comingsoon')}} className='opportunity-fifthfirstbtn'>Get Started</Button>

                            <Button  onClick={()=>{navigate('/contact')}} className='opportunity-fifthsecondbtn'>

                                Contact Us</Button>
                        </div>

                    </div>
                    {/* 
                    <img className='fifthimage' src={PngIcons.phone}></img> */}
                    <img alt='' className='opportunity-fifthimage' src={PngIcons.phone}></img>
                    <img alt='' className='frameimg' src={PngIcons.newframe}></img>
                    <div>

                    </div>
                </div>
            </div>




            {/* footer */}


            <div className='footer'>


                <div className='foootermain'>
                    <img alt='' className='footerlogo' src={PngIcons.footerlogo}>
                    </img>

                    <p className='footerpara'>
                        Our mission is to educate employees on recognizing and avoiding phishing scams, comprehending various cyber risks, and implementing cybersecurity best practices.
                    </p>

                    <img alt='' className='social' src={PngIcons.social}></img>

                </div>


                <div className='links'>

                    <div className='linksname'>
                        Home
                    </div>

                    <div className='linksname'>
                        About Us
                    </div>

                    <div className='linksname'>
                        Smishing 101
                    </div>

                    <div style={{ color: '#EF4444' }} className='linksname'>
                        Why Smish
                    </div>

                    <div className='linksname'>
                        Contact Us
                    </div>

                </div>

                <p className='linksname'>
                    © Copyright 2024 Smish Trap.
                </p>

            </div>



        </div>
    )
}


export default JoinSmish;