import { useState } from 'react'
import Button from 'react-bootstrap/Button';

import PngIcons from './icons/Pngincons';

import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import Slider from './Slider';
import NavbarComp from './Navbar';





const Contact = () => {
    const [phone, setPhone] = useState("");

  

    // const carouselData = [
    //     {
    //         name: "Chloe Williams",
    //         paragraph: "Consectetuer adipiscing",
    //         review: "Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Cras elementum."
    //     },
    //     {
    //         name: "John Williams",
    //         paragraph: "Security Analyst, ONG",
    //         review: "One of the easiest tools I’ve ever had to to setup and deploy"
    //     },
    //     {
    //         name: "Chloe Williams",
    //         paragraph: "Director, Security Operations",
    //         review: "Education is the only method we have to aid in prevention.  and this was one of the easiest tools to deploy."
    //     },
    //     {
    //         name: "James Smith",
    //         paragraph: "Nullam malesuada erat ut turpis",
    //         review: "Nullam malesuada erat ut turpis. Suspendisse urna nibh, viverra non, semper suscipit, posuere a, pede."
    //     },

    //     {
    //         name: "Liam Johnson",
    //         paragraph: "Pellentesque habitant",
    //         review: "Vestibulum tortor quam, feugiat vitae, ultricies eget, tempor sit amet, ante."
    //     },
    //     {
    //         name: "Olivia Brown",
    //         paragraph: "Aenean ultricies mi vitae est",
    //         review: "Aenean ultricies mi vitae est. Mauris placerat eleifend leo."
    //     },
    //     {
    //         name: "James Smith",
    //         paragraph: "Nullam malesuada erat ut turpis",
    //         review: "Nullam malesuada erat ut turpis. Suspendisse urna nibh, viverra non, semper suscipit, posuere a, pede."
    //     },

    //     {
    //         name: "Sophia Davis",
    //         paragraph: "Cras dapibus",
    //         review: "Cras dapibus. Vivamus elementum semper nisi. Aenean vulputate eleifend tellus."
    //     },
    //     {
    //         name: "Ethan Martinez",
    //         paragraph: "Quisque rutrum",
    //         review: "Quisque rutrum. Aenean imperdiet. Etiam ultricies nisi vel augue."
    //     },
    //     {
    //         name: "Emma Garcia",
    //         paragraph: "Curabitur ullamcorper",
    //         review: "Curabitur ullamcorper ultricies nisi. Nam eget dui. Etiam rhoncus."
    //     },
    //     {
    //         name: "Michael Rodriguez",
    //         paragraph: "Nullam dictum felis",
    //         review: "Nullam dictum felis eu pede mollis pretium. Integer tincidunt. Cras dapibus."
    //     },
    //     {
    //         name: "Ava Hernandez",
    //         paragraph: "Lorem ipsum dolor sit amet",
    //         review: "Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Cras elementum."
    //     }
    //     // Add more entries as needed
    // ];


    return (
        <div className='w-100' id='contactcontainer' >
  <NavbarComp/>


            <div className='contact-firstbox'>

                <p className='contact-firstabout'>
                    Contact Us
                </p>

                <h1 className='contact-firsthead'>
                    Get in Touch with Smish Trap
                    <p className='contact-firstpara'>
                        Reach out to Smish Trap for reliable solutions to safeguard against smishing attacks.
                    </p>
                </h1>

            </div>



            <div className='secondbox'>
                <div className='secondmain'>
                    <div className='contactdiv'>
                        <h3>
                            Rewards and more
                        </h3>
                        <h1>
                            Contact Us
                        </h1>

                        <p>
                            Red Team Analysts are asked to create an impossible list of attacks.  We were asked to solve this problem and felt like sharing it.  We tried to create a self service consumable product requiring no human intervention.  Our mission is to educate employees on identifying and avoiding phishing scams, understanding cyber risks, and implementing best practices.
                        </p>

                        <p style={{ marginTop: '20px' }}>Try us today and let's make the world safer together.</p>

                        <h2 className='follow'>
                            Follow us
                        </h2>
                        <img alt='' className='contactsocial' src={PngIcons.contactsocial}></img>
                    </div>

                    <div className='sendmessage'>

                        <div className='nametext'>
                            Full Name

                        </div>

                        <input type='text' placeholder='Enter your full name....' className='nameinput'></input>

                        <div className='nametext'>
                            Business Name

                        </div>

                        <input type="text" placeholder='Enter your business name....' className='nameinput'></input>


                        <div className='contactdisplay'>

                            <div style={{ width: '50%' }}>
                                <p className='nametext'>
                                    Phone Number
                                </p>

                                {/* <input type='phone' placeholder='Enter your Phone Number....' className='nameinput'></input> */}
                                <PhoneInput
                                 country={'us'} // default country code
                                    value={phone}
                                    onChange={(phone) => setPhone(phone)}

                                    inputStyle={{ padding: '12px 50px', height: '30%' }} // makes the input take full width

                                    inputClass='nameinput'
                                    placeholder="Enter your phone number..."
                                />
                            </div>



                            <div style={{ width: '50%' }}>

                                <p className='nametext'>
                                    Email Address
                                </p>
                                <input type='email' placeholder='Enter your email address...' className='nameinput'></input>

                            </div>




                        </div>





                        <div className='nametext'>
                            How can we help?

                        </div>

                        <input style={{ height: '109px' }} placeholder='Enter your message here...' className='nameinput'></input>

                        <Button className='sendmsgbtn'>

                            Send Message</Button>

                    </div>





                </div>
            </div>


            <div className='review-sixbox'>

                <div >
                    <h2 className='review-sixboxhead'>
                        Why people like us?
                    </h2>

                    <p className='review-sixboxpara'>
                        Let’s see what our customers are saying
                    </p>

                </div>




                <div className='carousel'>
                   <Slider/>
                </div>



            </div>







            {/* footer */}


            <div className='footer'>


                <div className='foootermain'>
                    <img className='footerlogo' alt='' src={PngIcons.footerlogo}>
                    </img>

                    <p className='footerpara'>
                        Our mission is to educate employees on recognizing and avoiding phishing scams, comprehending various cyber risks, and implementing cybersecurity best practices.
                    </p>

                    <img alt='' className='social' src={PngIcons.social}></img>

                </div>


                <div className='links'>

                    <div className='linksname'>
                        Home
                    </div>

                    <div className='linksname'>
                        About Us
                    </div>

                    <div className='linksname'>
                        Smishing 101
                    </div>

                    <div className='linksname'>
                        Why Smish
                    </div>

                    <div style={{ color: '#EF4444' }} className='linksname'>
                        Contact Us
                    </div>

                </div>

                <p className='linksname'>
                    © Copyright 2024 Smish Trap.
                </p>

            </div>



        </div>
    )
}


export default Contact;