var PngIcons = {


 logo:'/logo.png',
 user:'/user.png',
 ecliee:'/Ellipse.png',
 overview:'/updatedDesktop.png',
 iphone:'/iPhone14.png',
 union:'/MacBookAir.png',
 phone:'/frame.png',
 photo:'/Avatar.png',
 quote:'/Quote.png',
footerlogo:'/LogoDark.png',
social:'/Socials.png',
component:'/Component.png',
newframe:'/Framenew.png',

// smishing pics
smishingcomponent:'/smishingComponent.png',
smishingimg:'/smishingimage.png',
smsihingspam:'/spamComponent.png',
smishingphone:'/phonesmishingComponent.png',

//contact

contactsocial:'/contactSocials.png'


}


export default PngIcons;