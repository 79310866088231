
import './App.css';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Main from './components/Main';
import './styles/css/Global.scss';
import 'bootstrap/dist/css/bootstrap.min.css'
import About from './components/Aboutus';
import Smishing from './components/smishing';
import JoinSmish from './components/Joinus';
import Contact from './components/Contact';

import ComingSoon from './components/ComingSoon';


function App() {
  return (
    <div className="App">
        <BrowserRouter>

<Routes>
  <Route exact path="/"
    element={
      <Main />
    }
/>
    <Route exact path="/about"
    element={
      <About />
    }
/>
    <Route exact path="/smishing"
    element={
      <Smishing />
    }/>

<Route exact path="/joinsmishing"
    element={
      <JoinSmish />
    }/>
  

  <Route exact path="/contact"
    element={
      <Contact />
    }/>
  
  <Route exact path="/comingsoon"
    element={
      <ComingSoon />
    }/>
  
</Routes>

</BrowserRouter>
    </div>
  );
}

export default App;
