import React from 'react';
import { useLocation } from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import PngIcons from './icons/Pngincons';

const NavbarComp = () => {
  const location = useLocation();

  const getNavLinkStyle = (path) => {
    return location.pathname === path
      ? { color: '#EF4444', borderBottom: '2px solid #EF4444' }
      : {};
  };

  return (
    <div id='navbar'>
      <Navbar expand="lg" className="bg-body-tertiary">
        <Container className='navbarmain' fluid>
          <Navbar.Brand href="#">
            <img
              className='logoimg'
              style={{ width: '84px' }}
              src={PngIcons.logo}
              alt=''
            />
          </Navbar.Brand>

          <Navbar.Toggle aria-controls="navbarScroll" />
          <Navbar.Collapse id="navbarScroll">
            <Nav
              className="me-auto my-2 my-lg-0 gap"
              style={{ maxHeight: '100px', marginLeft: 'auto', gap: '25px' }}
              navbarScroll
            >
              <Nav.Link
                style={getNavLinkStyle('/')}
                className='nav'
                href="/"
              >
                Home
              </Nav.Link>

              <Nav.Link
                style={getNavLinkStyle('/about')}
                className='nav'
                href="/about"
              >
                About Us
              </Nav.Link>

              <Nav.Link
                style={getNavLinkStyle('/smishing')}
                className='nav'
                href="/smishing"
              >
                Smishing 101
              </Nav.Link>

              <Nav.Link
                style={getNavLinkStyle('/joinsmishing')}
                className='nav'
                href="/joinsmishing"
              >
                Why Smish
              </Nav.Link>

              <Nav.Link
                style={getNavLinkStyle('/contact')}
                className='nav'
                href="/contact"
              >
                Contact Us
              </Nav.Link>
            </Nav>
            <Form style={{ gap: '10px' }} className="d-flex">
              <Button className='newbtn'>
                <img
                  alt=''
                  className='noright'
                  style={{ position: 'relative', right: '28%' }}
                  width={18}
                  src={PngIcons.user}
                />
                Login
              </Button>

              <Button className='signin'>Get Started</Button>
            </Form>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </div>
  );
};

export default NavbarComp;
