
import PngIcons from './icons/Pngincons';
import NavbarComp from './Navbar';

const ComingSoon = () => {

  return (
    <div className='w-100' id='aboutcontainer'>

<NavbarComp/>


      <div className='firstbox'>
        <p className='firstabout'>
          We're Working!
        </p>
        <h1 className='firsthead'>
          SmishTrap is Coming to a Phone Near You.
          <p className='firstpara'>
            Expected Release in Q1 2025
          </p>
        </h1>

      </div>


      {/* footer */}
      <div style={{marginTop:'0px'}} className='footer'>

        <div className='foootermain'>
          <img alt='' className='footerlogo' src={PngIcons.footerlogo}>
          </img>
          <p className='footerpara'>
            Our mission is to educate employees on recognizing and avoiding phishing scams, comprehending various cyber risks, and implementing cybersecurity best practices.
          </p>

          <img alt='' className='social' src={PngIcons.social}></img>

        </div>

        <div className='links'>
          <div className='linksname'>
            Home
          </div>
          <div style={{ color: '#EF4444' }} className='linksname'>
            About Us
          </div>
          <div className='linksname'>
            Smishing 101
          </div>

          <div className='linksname'>
            Why Smish
          </div>

          <div className='linksname'>
            Contact Us
          </div>

        </div>

        <p className='linksname'>
          © Copyright 2024 Smish Trap.
        </p>

      </div>



    </div>
  )
}


export default ComingSoon;